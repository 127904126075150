export enum PaywallTrigger {
    pagesTemplatesColor = 'pages_templates_color',
    notificationPreference = 'notification_preference',
    backgroundChangePro = 'background_change_pro',
    backgroundAiChange = 'ai-bg-change',
    pagesTemplatesTexColor = 'pages_templates_text_color',
    pageTemplate = 'page-template',
    ai = 'ai',
    brandingColor = 'branding_color',
    brandingFont = 'branding_font',
    teams = 'teams',
    background_change = 'background_change',
    boost_audio = 'boost_audio',
    eye_contact = 'eye_contact-fix',
    videoMakerColors = 'video_maker_colors',
    videoMakerCreate = 'video_maker_create',
    uploadTake = 'upload_take',
    uploadMedia = 'upload_media',
    pagesListTemplate = 'pages_list_template',
    pagesTemplatesEdit = 'pages_templates_edit',
    liveStream = 'live_stream',
    thumbnailEditorColor = 'thumbnail_editor_color',
    navBar = 'nav_bar',
    domain = 'domain',
    linkedinlive = 'linkedin_live',
    translateTake = 'translate_take',
    generateImage = 'generate_image',
    thumbnailChange = 'thumbnail_change',
    clipExtractor = 'clip_extractor',
    audiogram = 'audiogram',
    onboarding = 'onboarding',
    aiMusic = 'ai_music',
    voiceClone = 'voice_clone',
    aiAvatarGenerate = 'ai-avatar-generate',
    aiAvatarCreate = 'ai-avatar-create',
    captions = 'apply-theme',
    pricingScreen = 'pricing-screen',
    offers = 'offers'
}